import React from 'react'

import PropTypes from 'prop-types'

import './narrator-card.css'

const NarratorCard = (props) => {
  return (
    <div className={`narrator-card-container`}>
      <div className="narrator-card-container1">
        <img
          alt={props.image_alt}
          src={props.image_src}
          loading="eager"
          className="narrator-card-image"
        />
        <h3 className="narrator-card-text">{props.name}</h3>
        <span className="narrator-card-text1">{props.position}</span>
      </div>
    </div>
  )
}

NarratorCard.defaultProps = {
  link_text: 'https://example.com',
  image_src: '/george-300w.jpeg',
  image_alt: 'image',
  position: 'CEO',
  rootClassName: '',
  name: 'Heading',
  link: '',
}

NarratorCard.propTypes = {
  link_text: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  position: PropTypes.string,
  rootClassName: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
}

export default NarratorCard
