// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSZkP6LnshC7Iv32MgfbJBZmJ6CdOs6mM",
 authDomain: "respiro-website-prod.firebaseapp.com",
 projectId: "respiro-website-prod",
 storageBucket: "respiro-website-prod.appspot.com",
 messagingSenderId: "730150455351",
 appId: "1:730150455351:web:cb3b5e3ac8af6875b157b2",
 measurementId: "G-1E2G2Y2HGM"
//  apiKey: 'AIzaSyC2lusmNTbPpBn_IvGg1JxpxY3sPmPE7ZU',
//  authDomain: 'repirowebsite.firebaseapp.com',
//  projectId: 'repirowebsite',
//  storageBucket: 'repirowebsite.appspot.com',
//  messagingSenderId: '773201177106',
//  appId: '1:773201177106:web:69d9c7a098e009585a06cb',
//  measurementId: 'G-6M36PJ7VPZ',
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { app, firestore, analytics };
