import React, { useEffect } from "react";
import Footer from "../components/footer";

import "./privacy-policy.css";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container">
        <h1>Terms and Conditions</h1>
        <p>Last updated: November 06, 2023</p>
        <p>
          Please read these terms and conditions carefully before using Our
          Service.
        </p>
        <h1>Interpretation and Definitions</h1>
        <h2>Interpretation</h2>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h2>Definitions</h2>
        <p>For the purposes of these Terms and Conditions:</p>
        <ul>
          <li>
            <p>
              <strong>Application (The App)</strong> means the software program
              provided by the Company downloaded by You on any electronic
              device, named Respiro
            </p>
          </li>
          <li>
            <p>
              <strong>The Platform</strong> means the web application for
              therapists provided by the Company, named Respiro
            </p>
          </li>
          <li>
            <p>
              <strong>Application Store</strong> means the digital distribution
              service operated and developed by Apple Inc. (Apple App Store) or
              Google Inc. (Google Play Store) in which the Application has been
              downloaded.
            </p>
          </li>
          <li>
            <p>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election
              of directors or other managing authority.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: Romania
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to RESPIROTECHNOLOGIES S.R.L, D. D.
              Roșca 12, Cluj-Napoca, 400497.
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Feedback</strong> means feedback, innovations or
              suggestions sent by You regarding the attributes, performance or
              features of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Application and the
              Platform.
            </p>
          </li>
          <li>
            <p>
              <strong>Terms and Conditions</strong> (also referred as
              &quot;Terms&quot;) mean these Terms and Conditions that form the
              entire agreement between You and the Company regarding the use of
              the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Third-party Social Media Service</strong> means any
              services or content (including data, information, products or
              services) provided by a third-party that may be displayed,
              included or made available by the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
        <h1>Acknowledgment</h1>
        <p>
          These are the Terms and Conditions governing the use of this Service
          and the agreement that operates between You, as a (i) person using the
          App in order to learn more about your well-being condition and to
          provide you exercises and tools to improve your mental health or a
          (ii) specialist using The Platform to manage the therapy process
          between you and your clients, and the Company . These Terms and
          Conditions set out the rights and obligations of all users regarding
          the use of the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on Your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all visitors, users and others who
          access or use the Service.
        </p>
        <p>
          By accessing or using the Service You agree to be bound by these Terms
          and Conditions. If You disagree with any part of these Terms and
          Conditions then You may not access the Service. The use of the Service
          represents your confirmation that you understand and agree to all of
          these Terms. If you are using the Services on behalf of an
          organization, you are agreeing to these Terms for that organization
          and promising that you have the authority to bind that organization to
          these Terms. In that case, “you” and “your” will also refer to that
          organization, wherever possible.
        </p>
        <p>
          You represent that you are over the age of 18. The Company does not
          permit those under 18 to use the Service.If you are over 18 years of
          age but are not yet of legal age to form a binding contract, then you
          must get your parent or guardian to read these Terms and agree to them
          for you before you use the Services. If you are a parent or guardian
          and you provide your consent to your child’s registration with the
          Services, you agree to be bound by these Terms with respect of your
          child’s use of the Services.
        </p>
        <p>
          Your access to and use of the Service is also conditioned on Your
          acceptance of and compliance with the Privacy Policy of the Company.
          Our Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your personal information when You
          use the Application or the Website and tells You about Your privacy
          rights and how the law protects You. Please read Our Privacy Policy
          carefully before using Our Service.
        </p>
        <h1>Intellectual Property</h1>
        <p>
          The Service and its original content (excluding Content provided by
          You or other users), features and functionality are and will remain
          the exclusive property of the Company and its licensors.
        </p>
        <p>
          The Service is protected by copyright, trademark, and other laws of
          both the Country and foreign countries.
        </p>
        <p>
          Our trademarks and trade dress may not be used in connection with any
          product or service without the prior written consent of the Company.
        </p>
        <p>
          You agree not to: reproduce, copy, distribute, modify, publish,
          transmit, display, use, reuse, re-publicize, assign, sublicense, sell,
          or exploit for any public or commercial purpose, any portion of the
          App, use of the App, or access to the App; attempt to change, add to,
          remove, deface, hack or otherwise interfere with the App or any
          material or content displayed on the App; and/or access or use the App
          in any way that could or is intended to damage or impair the App, or
          any server or network underlying the App, or interfere with anyone
          else's use and enjoyment of the App.
        </p>
        <h1>The Purpose of the Service</h1>
        <h3>App</h3>
        <p>
          The App is created for purposes of personal development of users and
          serves as a platform for self-realization through built-in tools
          within the App. The App is based on analyzing of some of the User’s
          data. These data serve as source for evaluating the User’s mental
          environment. The data are therefore processed within the App and the
          User shall receive an overview of impacts of different situations in
          his life on his peace of mind. Following the analyzes of these data
          the App provides online and mobile meditation, mindfulness, sleep and
          movement content in the health & wellness space. Within the App, the
          Users can also collaborate with their therapist in order to improve
          the therapy process. The therapist is able to establish homeworks for
          the users, based on the content provided by Us. The App is not
          intended to be a substitute for professional medical advice, diagnosis
          or treatment. Always seek the advice of your therapist or other.
        </p>
        <h3>Platform</h3>
        <p>
          The Platform is created for purposes of client management and serves
          as a platform for therapy process management. The data are therefore
          processed within the Platform and the User shall receive an overview
          of impacts of different situations in his life on his peace of mind.
          Following the analyzes of these data the therapist can assign
          homeworks based on the content provided by Us.
        </p>

        <h1>Registration</h1>
        <p>
          The App may be only used by registered users. The registration is made
          through either (i) Google Account or email-password account; and the
          name, email and photograph provided by these networks are used within
          the App. In case of invalid or unreal data, we are entitled to delete
          the respective account. You may also delete your account including all
          your provided data at any time. The download of the App is possible
          even without the registration.
        </p>

        <h1>User Consent</h1>
        <p>
          You are solely responsible for all content you create, transmit and/or
          distribute through the Service.
        </p>
        <p>
          With that in mind, you agree not to create, transmit and/or distribute
          through the Service any User Content that:
          <ul>
            <li>
              is illegal or unlawful, that would constitute, encourage or
              provide instructions for a criminal offense, violate the rights of
              any party, or otherwise create liability or violate any local,
              national or international law
            </li>
            <li>
              is defamatory, profane, obscene, pornographic, sexually explicit,
              indecent, lewd, vulgar, suggestive, violent, harassing, hateful,
              threatening, offensive, discriminatory, bigoted, abusive,
              inflammatory, invasive of privacy or publicity rights, fraudulent,
              deceptive or otherwise objectionable
            </li>
            <li>
              impersonates any person or entity or otherwise misrepresents your
              affiliation with a person or entity
            </li>
            <li>
              may infringe or violate any patent, trademark, trade secret,
              copyright, or other intellectual property right or other right of
              any party
            </li>
            <li>
              contains or depicts any statements, remarks or claims that do not
              reflect your honest views and experiences;
            </li>
            <li>is designed to deceive or trick the users of the Service;</li>
          </ul>
        </p>

        <h1>User Conduct</h1>
        <p>
          You are solely responsible for your own conduct while accessing or
          using the Service. You agree to use the Service only for purposes that
          are legal, proper and in accordance with these Terms and any
          applicable laws or regulations. You agree that you will not and will
          not permit any third party to do, including but not limited to, any of
          the following:
          <ul>
            <li>
              use the Service for any illegal or unauthorized purpose or engage
              in, encourage, or promote any illegal activity, or any activity
              that violates these Terms or any other rules or policies
              established from time to time by us
            </li>
            <li>
              use the App and the Platform to violate the legal rights and the
              legitimate interests of others, including, but not limited to,
              transmitting or otherwise making available through the App and the
              Platform of content that infringes the intellectual proprietary
              rights of any party
            </li>
            <li>
              remove any copyright, trademark or other proprietary rights
              notices contained in or on the App and the Platform, or any part
              of it; modify, adapt, hack, translate, or reverse engineer the
              App and the Platform 
            </li>
            <li>
              modify, adapt, hack, translate, or reverse engineer the App and the
              Platform; use any robot, spider, crawler,
              scraper or other automated means or interface not provided by us
              to access the App and the Platform or to extract
              data
            </li>
            <li>
              attempt to indicate in any manner that you have a relationship
              with us or that we have endorsed you or any products or services
              for any purpose, unless we specifically consented to such conduct;
              upload, send, distribute or disseminate any User Content that
              could be in any way interpreted as defamatory, unlawful,
              fraudulent, obscene, harassing or objectionable
            </li>
            <li>
              distribute any other harmful components such as, including but not
              limited to, worms, viruses, Trojan horses, corrupted files,
              defects, hoaxes; impersonate another person by any mean (e.g., by
              use of an email address, name, nickname or otherwise)
            </li>
            <li>
              exploit the App and the Platform for any
              unauthorized commercial purpose
            </li>
            <li>
              access or use the App and the Platform for the
              purpose of creating a product or service competitive with any of
              our products or services
            </li>
            <li>
              register on the App and the Platform on behalf
              of a company, without having the right to represent the respective
              company;
            </li>
          </ul>
        </p>
        <h1>Your Feedback to Us</h1>
        <p>
          You assign all rights, title and interest in any Feedback You provide
          the Company. If for any reason such assignment is ineffective, You
          agree to grant the Company a non-exclusive, perpetual, irrevocable,
          royalty free, worldwide right and license to use, reproduce, disclose,
          sub-license, distribute, modify and exploit such Feedback without
          restriction.
        </p>
        <h1>Links to Other Websites</h1>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by the Company.
        </p>
        <p>
          The Company has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third party web
          sites or services. You further acknowledge and agree that the Company
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          the use of or reliance on any such content, goods or services
          available on or through any such web sites or services.
        </p>
        <p>
          We strongly advise You to read the terms and conditions and privacy
          policies of any third-party web sites or services that You visit.
        </p>
        <h1>Termination</h1>
        <p>
          We may terminate or suspend Your access immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if You breach these Terms and Conditions.
        </p>
        <p>
          Upon termination, Your right to use the Service will cease
          immediately.
        </p>
        <h1>Limitation of Liability</h1>
        <p>
          Notwithstanding any damages that You might incur, the entire liability
          of the Company and any of its suppliers under any provision of this
          Terms and Your exclusive remedy for all of the foregoing shall be
          limited to the amount actually paid by You through the Service or 100
          USD if You haven't purchased anything through the Service.
        </p>
        <p>
          To the maximum extent permitted by applicable law, in no event shall
          the Company or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, loss of data or other
          information, for business interruption, for personal injury, loss of
          privacy arising out of or in any way related to the use of or
          inability to use the Service, third-party software and/or third-party
          hardware used with the Service, or otherwise in connection with any
          provision of this Terms), even if the Company or any supplier has been
          advised of the possibility of such damages and even if the remedy
          fails of its essential purpose.
        </p>
        <p>
          Some states do not allow the exclusion of implied warranties or
          limitation of liability for incidental or consequential damages, which
          means that some of the above limitations may not apply. In these
          states, each party's liability will be limited to the greatest extent
          permitted by law.
        </p>
        <h1>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h1>
        <p>
          The Service is provided to You &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the
          Company, on its own behalf and on behalf of its Affiliates and its and
          their respective licensors and service providers, expressly disclaims
          all warranties, whether express, implied, statutory or otherwise, with
          respect to the Service, including all implied warranties of
          merchantability, fitness for a particular purpose, title and
          non-infringement, and warranties that may arise out of course of
          dealing, course of performance, usage or trade practice. Without
          limitation to the foregoing, the Company provides no warranty or
          undertaking, and makes no representation of any kind that the Service
          will meet Your requirements, achieve any intended results, be
          compatible or work with any other software, applications, systems or
          services, operate without interruption, meet any performance or
          reliability standards or be error free or that any errors or defects
          can or will be corrected.
        </p>
        <p>
          WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES, HOWSOEVER CAUSED, RESULTING FROM
          YOUR ACCESS TO, USE OF OR INABILITY TO USE, RELIANCE ON OR DOWNLOADING
          FROM THE APP, OR ANY DELAYS, INACCURACIES IN THE INFORMATION OR IN ITS
          TRANSMISSION INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF BUSINESS
          OR PROFITS, USE, DATA OR OTHER INTANGIBLE, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          Without limiting the foregoing, neither the Company nor any of the
          company's provider makes any representation or warranty of any kind,
          express or implied: (i) as to the operation or availability of the
          Service, or the information, content, and materials or products
          included thereon; (ii) that the Service will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any
          information or content provided through the Service; or (iv) that the
          Service, its servers, the content, or e-mails sent from or on behalf
          of the Company are free of viruses, scripts, trojan horses, worms,
          malware, timebombs or other harmful components. While we may use
          reasonable efforts to include accurate and up-to-date information on
          the App, we make no warranties or representations as to its accuracy,
          timeliness or completeness.
        </p>
        <p>
          Notwithstanding our efforts to ensure that our Site/system is secure,
          you acknowledge that all electronic data transfers are potentially
          susceptible to interception by others. We cannot, and do not, warrant
          that data transfers pursuant to the App, or electronic mail
          transmitted to and from us, will not be monitored or read by others.
          Although we use reasonable endeavors to ensure that any software made
          available on the App is suitable for downloading, installation and use
          by you, all such software is provided "as is" without any warranty.
          Specifically, and without limitation, we do not warrant that any such
          software is virus free, without defects, compatible with other
          software or operating systems or suitable for any specific purpose. We
          accept no liability for any loss or damage caused by the downloading,
          installation or use of any such software, and the general exclusions
          and limitations above apply to such downloading, installation or use
          by you.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a
          consumer, so some or all of the above exclusions and limitations may
          not apply to You. But in such a case the exclusions and limitations
          set forth in this section shall be applied to the greatest extent
          enforceable under applicable law.
        </p>
        <h1>Medical Disclaimer</h1>
        <p>
          The App provides online and mobile self-reflection, journaling,
          meditation, mindfulness content in the app. We are not a health care
          or medical device provider, nor should our Products be considered
          medical advice. Only your physician or other health care provider can
          do that. While there is third party evidence from research that
          meditation can assist in the prevention and recovery process for a
          wide array of conditions as well as in improving some performance and
          relationship issues, Respiro makes no claims, representations or
          guarantees that the App provide a physical or therapeutic benefit. Any
          health information and links within the App, whether provided by
          Respiro or by contract from outside providers, is provided simply for
          your convenience. You should consult a licensed physician prior to
          beginning or modifying any exercise program that you undertake,
          especially if you have a prior injury, a history of heart disease,
          high blood pressure, other chronic illness, or condition. You
          acknowledge that Respiro has advised you of the necessity of doing so.
          Any advice or other materials within the App are intended for general
          information purposes only. They are not intended to be relied upon and
          are not a substitute for professional medical advice based on your
          individual condition and circumstances. The advice and other materials
          we make available are intended to support the relationship between you
          and your healthcare providers and not replace it. We are not liable or
          responsible for any consequences of your having read or been told
          about such advice or other materials as you assume full responsibility
          for your decisions and actions. In particular, to the fullest extent
          permitted by law, we make no representation or warranties about the
          accuracy, completeness, or suitability for any purpose of the advice,
          other materials and information published as part of the App. During
          the use of this App, the users acknowledge that Respiro is not a
          medical device, and therefore we do not predict any mental issues,
          mental diseases, attacks, etc. We are not liable or responsible for
          any health issues and their not-solving. There have been rare reports
          where people with certain psychiatric problems like anxiety and
          depression have experienced worsening conditions in conjunction with
          intensive self-reflection or meditation practice. People with existing
          mental health conditions should speak with their health care providers
          before starting using the App. You agree that you are voluntarily
          participating in the Services and do so at your own risk.
        </p>
        <h1>Personal Data</h1>
        <p>
          For the purposes of the registration into the App and the use of the
          App, we collect and use your personal data. We collect and use your
          personal data in accordance with the respective data protection
          regulations, in particular: (1) Before or at the time of collecting
          personal date, we identify the purposes for which data is being
          collected. (2) We collect and use of personal data solely with the
          objective of fulfilling those purposes specified by us and for other
          compatible purposes, unless we obtain the consent of the individual
          concerned or as required by law. (3) All provided personal data is
          being anonymized by us and is accessible only by authorized persons.
          (4) We only retain personal data as long as necessary for the
          fulfilment of those purposes. 8.1.5. We collect personal data by
          lawful and fair means and, where appropriate, with the knowledge or
          consent of the individual concerned. 8.1.6. Personal data should be
          relevant to the purposes for which it is to be used, and, to the
          extent necessary for those purposes, should be accurate, complete, and
          up to date. (5) We protect personal data by reasonable security
          safeguards against loss or theft, as well as unauthorized access,
          disclosure, copying, use or modification.
        </p>
        <h1>Governing Law</h1>
        <p>
          The laws of the Country, excluding its conflicts of law rules, shall
          govern this Terms and Your use of the Service. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
        </p>
        <h1>Disputes Resolution</h1>
        <p>
          If You have any concern or dispute about the Service, You agree to
          first try to resolve the dispute informally by contacting the Company.
        </p>
        <h1>For European Union (EU) Users</h1>
        <p>
          If You are a European Union consumer, you will benefit from any
          mandatory provisions of the law of the country in which you are
          resident in.
        </p>
        <h1>United States Legal Compliance</h1>
        <p>
          You represent and warrant that (i) You are not located in a country
          that is subject to the United States government embargo, or that has
          been designated by the United States government as a &quot;terrorist
          supporting&quot; country, and (ii) You are not listed on any United
          States government list of prohibited or restricted parties.
        </p>
        <h1>Severability and Waiver</h1>
        <h2>Severability</h2>
        <p>
          If any provision of these Terms is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </p>
        <h2>Waiver</h2>
        <p>
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under these Terms shall not
          effect a party's ability to exercise such right or require such
          performance at any time thereafter nor shall the waiver of a breach
          constitute a waiver of any subsequent breach.
        </p>
        <h1>Translation Interpretation</h1>
        <p>
          These Terms and Conditions may have been translated if We have made
          them available to You on our Service. You agree that the original
          English text shall prevail in the case of a dispute.
        </p>
        <h1>Changes to These Terms and Conditions</h1>
        <p>
          We reserve the right, at Our sole discretion, to modify or replace
          these Terms at any time. If a revision is material We will make
          reasonable efforts to provide at least 30 days' notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at Our sole discretion.
        </p>
        <p>
          By continuing to access or use Our Service after those revisions
          become effective, You agree to be bound by the revised terms. If You
          do not agree to the new terms, in whole or in part, please stop using
          the website and the Service.
        </p>
        <h1>Contact Us</h1>
        <p>
          If you have any questions about these Terms and Conditions, You can
          contact us:
        </p>
        <ul>
          <li>By email: contact@respirotechnologies.com</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
