import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './navbar.css';

const Navbar = (props) => {
  const { t, i18n } = useTranslation();

  const onRoClick = () => {
    i18n.changeLanguage('ro');
  };

  const onENClick = () => {
    i18n.changeLanguage('en');
  };

  const handleMobileMenuOpen = () => {
    const mobileMenu = document.querySelector('#mobile-menu');
    mobileMenu.style.transform = 'translateX(20%)';
  };

  const handleMobileMenuClose = () => {
    const mobileMenu = document.querySelector('#mobile-menu');
    mobileMenu.style.transform = 'translateX(100%)';
  };

  // const handleLinkClick = (link) => {
  //   const destination = document.querySelector(`#${link}`);
  //   if (destination) {
  //     destination.scrollIntoView(true);
  //     handleMobileMenuClose()
  //   }
  // }

  return (
    <nav className={`navbar-navbar ${props.rootClassName} `}>
      <div className="navbar-desktop">
        <div className="navbar-main">
          <Link className="navbar-branding" to="/">
            <img alt={props.image_alt1} src={props.image_src1} className="navbar-image" />
            <h2 className="navbar-text">{props.text}</h2>
          </Link>
          <div className="navbar-links">
            <Link to="/" className="navbar-link" >
              {t('navbar.home')}
            </Link>
            <Link to="/team" id="presentation-link" className="navbar-link1">
              {t('navbar.team')}
            </Link>
          </div>
        </div>
        <div className="navbar-container">
          <div className="navbar-container1">
            <div data-thq="thq-dropdown" className="navbar-thq-dropdown list-item">
              <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle">
                <i className="navbar-text1"></i>
                {/* <div data-thq="thq-dropdown-arrow" className="navbar-dropdown-arrow">
                  <svg viewBox="0 0 1024 1024" className="navbar-icon">
                    <path d="M426 726v-428l214 214z" className=""></path>
                  </svg>
                </div> */}
              </div>
              <ul data-thq="thq-dropdown-list" className="navbar-dropdown-list">
                <li data-thq="thq-dropdown" className="navbar-dropdown list-item">
                  <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle1" onClick={onRoClick}>
                    <span className="navbar-text2">{t('languages.romanian')}</span>
                  </div>
                </li>
                <li data-thq="thq-dropdown" className="navbar-dropdown1 list-item">
                  <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle2" onClick={onENClick}>
                    <span className="navbar-text3">{t('languages.english')}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='navbar-container1'>
          <a href="mailto:contact@respirotechnologies.com?subject=" className="contact-link">
            <span> {t('navbar.button')}</span>
            <br></br>
          </a>
          </div>
          <div className="navbar-quick-actions">
            <img id="open-mobile-menu" alt={props.pastedImage_alt} src={props.pastedImage_src} className="navbar-hamburger-menu" onClick={handleMobileMenuOpen}/>
          </div>
        </div>
      </div>
      <div id="mobile-menu" className="navbar-mobile">
        <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "column", gap: 60, width: "100%"}}>
          <div className="navbar-top">
            <svg id="close-mobile-menu" viewBox="0 0 1024 1024" className="navbar-icon2" onClick={handleMobileMenuClose}>
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z" className=""></path>
            </svg>
          </div>

          <div className="navbar-links1">
            <a href="/" className="navbar-link2" id="mobile-home-link" >
              {t('navbar.home')}
            </a>
            <a href="/team" className="navbar-link2" id="mobile-team-link" >
              {t('navbar.team')}
            </a>
          </div>
        </div>

   
        
        <div className="footer-column" >
            <span className="footer-header">{t("navbar.button")}</span>
            <a href="mailto:contact@respirotechnologies.com?subject=" onClick={() => handleMobileMenuClose()}>contact@respirotechnologies.com</a>
          </div>
        </div>
      
      
    </nav>
  );
};

Navbar.defaultProps = {
  text4: 'Echipă',
  text1: '',
  link_text: 'https://example.com',
  text2: 'Despre',
  image_alt1: 'image',
  text31: 'Caracteristici',
  image_alt2: 'image',
  text3: 'Contact',
  text41: 'Prezentarea aplicației',
  image_src1: '/logo_no_bg-1500h.png',
  image_alt: 'image',
  pastedImage_src: '/pastedimage-8o98.svg',
  text21: 'Introducere în mindfulness',
  text: 'Respiro',
  image_src2: 'https://play.teleporthq.io/static/svg/default-img.svg',
  pastedImage_alt: 'pastedImage',
  image_src: '/logo_no_bg-1500h.png',
  rootClassName: '',
  Link5: "contact@respirotechnologies.com",
};

Navbar.propTypes = {
  text4: PropTypes.string,
  text1: PropTypes.string,
  link_text: PropTypes.string,
  text2: PropTypes.string,
  image_alt1: PropTypes.string,
  text31: PropTypes.string,
  image_alt2: PropTypes.string,
  text3: PropTypes.string,
  text41: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt: PropTypes.string,
  pastedImage_src: PropTypes.string,
  text21: PropTypes.string,
  text: PropTypes.string,
  image_src2: PropTypes.string,
  pastedImage_alt: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default Navbar;
