import React from 'react';
import { useHistory } from 'react-router-dom';

import Script from 'dangerous-html/react';
import { Helmet } from 'react-helmet';

import Highlight from '../components/highlight';
import Check from '../components/check';
import Quote from '../components/quote';
import Footer from '../components/footer';
import './home.css';
import AudioPlayer from '../components/player/AudioPlayer';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from 'react-i18next';

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useHistory();

  return (
    <div className="home-container">
      <Helmet>
        <title>Respiro</title>
        <meta name="description" content="Description of the website" />
        <meta property="og:title" content="Respiro" />
        <meta property="og:description" content="Description of the website" />
      </Helmet>
      <div className="home-header" id="home-header">
        <div className="home-hero">
          <div className="home-container01">
            <div className="home-content">
              <div className="home-container02">
                <h1 className="home-title">
                  <span className="home-text">Respiro</span>
                  <br></br>
                </h1>
                <span className="home-caption">{t('home.description')}</span>
              </div>
              <div className="home-hero-buttons">
                <div className="home-io-btn">
                  <a href="https://apps.apple.com/ro/app/respiro-mindfulness-assistant/id6449878355" target="_blank" rel="noopener noreferrer">
                    <img
                      alt="image"
                      src={t('home.iosBadge')}
                      className="home-image"
                      style={{ padding: i18n.language.includes('en') ? 12.5 : 0 }}
                    />
                  </a>
                </div>
                <div className="home-io-btn">
                  <a href="https://play.google.com/store/apps/details?id=com.respirotechnologies&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel="noopener noreferrer">
                    <img alt="image" src={t('home.androidBadge')} className="home-image" />
                  </a>
                </div>
              </div>
            </div>
            <div className="home-container04">
              <img alt="pastedImage" src={t('home.image')} className="home-pasted-image" />
            </div>
          </div>
        </div>
        <div className="home-container05">
          <div className="home-container06">
            <img alt="image" src="/corner_shape-1500h.png" className="home-image02" />
          </div>
        </div>
      </div>
      <div id="preview-section" className="home-sections">
        <div className="home-container07">
          <h2 className="home-text02">
            <span>{t('home.features.title')}</span>
            <br></br>
          </h2>
        </div>
        <div className="home-section">
          <div className="home-container08">
            <img alt="image" src="/card1-1500w.png" className="home-image03" />
            <div className="home-container09">
              <Highlight
                Title={t('home.features.box0.title')}
                Description={t('home.features.box0.description')}
                rootClassName="highlight-root-class-name8"></Highlight>
            </div>
          </div>
        </div>
        <div className="home-section1">
          <div className="home-container10">
            <img alt="image" src="/card4-1500w.png" className="home-image04" />
            <div className="home-container11">
              <span className="home-text05">{t('home.features.box1.title')}</span>
              <span className="home-text06">{t('home.features.box1.description')}</span>
            </div>
          </div>
          <div className="home-container12">
            <img alt="image" src="/card3-1500w.png" className="home-image05" />
            <div className="home-container13">
              <span className="home-text07">{t('home.features.box2.title')}</span>
              <span className="home-text08">{t('home.features.box2.description')}</span>
            </div>
          </div>
        </div>
        <div className="home-section">
          <div className="home-container14">
            <div className="home-container09">
              <Highlight
                Title={t('home.features.box3.title')}
                Description={t('home.features.box3.description')}
                rootClassName="highlight-root-class-name6"></Highlight>
            </div>
            <div>
              <img alt="image" src="/card2-1500w.png" className="home-image06" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-container16">
        <span className="home-text11">{t('home.testimonials.title')}</span>
      </div>
      <div className="home-testimonial-container">
        <Carousel>
          <div data-role="current-banner" className="home-slide">
            <div className="home-testimonial-card">
              <div className="home-testimonial">
                <svg viewBox="0 0 1024 1024" className="home-icon">
                  <path d="M225 448c123.712 0 224 100.29 224 224 0 123.712-100.288 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.634 11.636-22.252 24.016-31.83 37.020 11.438-1.8 23.16-2.746 35.104-2.746zM801 448c123.71 0 224 100.29 224 224 0 123.712-100.29 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.636 11.636-22.254 24.016-31.832 37.020 11.44-1.8 23.16-2.746 35.106-2.746z"></path>
                </svg>
                <span className="home-text12">
                  <span className="home-text19">{t('home.testimonials.testimonial1')}</span>
                  <br></br>
                </span>
                <div style={{ alignSelf: 'flex-end' }}>
                  <span className="home-text15">-Nicolle, Cluj-Napoca</span>
                </div>
              </div>
            </div>
          </div>
          <div data-role="current-banner" className="home-slide banner">
            <div className="home-testimonial-card">
              <div className="home-testimonial">
                <svg viewBox="0 0 1024 1024" className="home-icon">
                  <path d="M225 448c123.712 0 224 100.29 224 224 0 123.712-100.288 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.634 11.636-22.252 24.016-31.83 37.020 11.438-1.8 23.16-2.746 35.104-2.746zM801 448c123.71 0 224 100.29 224 224 0 123.712-100.29 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.636 11.636-22.254 24.016-31.832 37.020 11.44-1.8 23.16-2.746 35.106-2.746z"></path>
                </svg>
                <span className="home-text12">
                  <span className="home-text19">{t('home.testimonials.testimonial2')}</span>
                  <br></br>
                </span>
                <div style={{ alignSelf: 'flex-end' }}>
                  <span className="home-text15">-Cristina, Cluj-Napoca</span>
                </div>
              </div>
            </div>
          </div>
          <div data-role="current-banner" className="home-slide banner">
            <div className="home-testimonial-card">
              <div className="home-testimonial">
                <svg viewBox="0 0 1024 1024" className="home-icon">
                  <path d="M225 448c123.712 0 224 100.29 224 224 0 123.712-100.288 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.634 11.636-22.252 24.016-31.83 37.020 11.438-1.8 23.16-2.746 35.104-2.746zM801 448c123.71 0 224 100.29 224 224 0 123.712-100.29 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.636 11.636-22.254 24.016-31.832 37.020 11.44-1.8 23.16-2.746 35.106-2.746z"></path>
                </svg>
                <span className="home-text12">
                  <span className="home-text19"> {t('home.testimonials.testimonial3')}</span>
                  <br></br>
                </span>
                <div style={{ alignSelf: 'flex-end' }}>
                  <span className="home-text15">-Emilia, Timișoara</span>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div id="video-section" className="home-video">
        <div className="home-content1">
          <div className="home-header1">
            <h2 className="home-text22">{t('home.meditation')}</h2>
          </div>

          <div id="video-section" className="home-video-container">
            <div className="home-video1">
              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <AudioPlayer src="/meditation_audio.mp3"></AudioPlayer>
              </div>
            </div>

            <div className="home-container1">
              <div className="home-container2">
                <span className="narrator">Narator: Simion Echim - Consilier psihologic CBT și Trainer ACT </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="features-section" className="home-media">
        <div className="home-header2">
          <h2 className="home-text23">{t('home.media')}</h2>
        </div>
        <div className="home-media-appearances">
          <div className="home-container17">
            <a href="https://example.com" target="_blank" rel="noreferrer noopener" className="home-link">
              <br></br>
              <br></br>
            </a>
          </div>
          <div className="home-container18">
            <a href="https://example.com" target="_blank" rel="noreferrer noopener" className="home-link1"></a>
          </div>
        </div>
      </div>
      <div id="team-section" className="home-team">
        <div className="home-header3">
          <div className="home-heading1">
            <h2 className="home-text26">
              <span className="home-text27">{t('home.team.title')}</span>
              <br></br>
            </h2>
          </div>
        </div>
        <div className="home-respiro-team">
          <div className="home-container19">
            <img alt="image" src="/respiroteam-1500w.png" className="home-image07" />
          </div>
          <div className="home-container20">
            <span className="home-text29">{t('home.team.description')}</span>
            <div className="home-container21" onClick={() => navigate.push('/team')}>
              <div to="/team" className="home-navlink">
                <span className="home-text30">{t('home.team.seeAll')}</span>
                <br></br>
              </div>
              <img alt="image" src="/arrow.right.svg" className="home-image08" />
            </div>
          </div>
        </div>
        <div className="home-container22">
          <div className="home-container23">
            <img alt="image" src="/corner_shape-1500h.png" className="home-image09" />
          </div>
        </div>
      </div>
      <div className="home-pricing">
        <div className="home-content2">
          <div className="home-header4">
            <div className="home-heading2">
              <h2 className="home-text32">Fara costuri ascunse</h2>
            </div>
          </div>
          <div className="home-pricing-plans">
            <div className="home-plans">
              <div className="home-plan">
                <div className="home-top">
                  <div className="home-heading3">
                    <span className="home-text33">Standard</span>
                  </div>
                  <div className="home-cost">
                    <span className="home-text34">Gratis</span>
                  </div>
                </div>
                <div className="home-bottom">
                  <div className="home-check-list">
                    <Check Feature="Content limitat"></Check>
                    <Check Feature="Informatii despre sesiuni limitate"></Check>
                    <Check Feature="Istoric"></Check>
                    <Check Feature="Metrici limitate "></Check>
                  </div>
                  <div className="home-button">
                    <span className="home-text35">Get Standard</span>
                  </div>
                </div>
              </div>
              <div className="home-plan1">
                <div className="home-top1">
                  <div className="home-heading4">
                    <span className="home-text36">Premium</span>
                  </div>
                  <div className="home-cost1">
                    <span className="home-text37">25 RON</span>
                    <span className="home-text38">/month</span>
                  </div>
                </div>
                <div className="home-bottom1">
                  <div className="home-check-list1">
                    <Check Feature="Access la tot contentul"></Check>
                    <Check Feature="Informații premium"></Check>
                    <Check Feature="Istoric"></Check>
                    <Check Feature="Metrici detaliate"></Check>
                  </div>
                  <div className="home-button1">
                    <span className="home-text39">Get Premium</span>
                  </div>
                </div>
              </div>
              <div className="home-plan2">
                <div className="home-top2">
                  <div className="home-heading5">
                    <span className="home-text40">Student</span>
                  </div>
                  <div className="home-cost2">
                    <span className="home-text41">5RON</span>
                    <span className="home-text42">/month</span>
                  </div>
                </div>
                <div className="home-bottom2">
                  <div className="home-check-list2">
                    <Check Feature="Access Premium "></Check>
                  </div>
                  <div className="home-button2">
                    <span className="home-text43">Get Student</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-plans1">
            <div className="home-plan3">
              <div className="home-top3">
                <div className="home-heading6">
                  <span className="home-text44">Standard</span>
                </div>
                <div className="home-cost3">
                  <span className="home-text45">Gratis</span>
                </div>
              </div>
              <div className="home-bottom3">
                <div className="home-check-list3">
                  <Check Feature="Continut limitat"></Check>
                  <Check Feature="Informatii despre sesiuni limitate"></Check>
                  <Check Feature="Istoric"></Check>
                  <Check Feature="Metrici limitate"></Check>
                </div>
                <div className="home-button3">
                  <span className="home-text46">Get Standard</span>
                </div>
              </div>
            </div>
            <div className="home-plan4">
              <div className="home-top4">
                <div className="home-heading7">
                  <span className="home-text47">Premium</span>
                </div>
                <div className="home-cost4">
                  <span className="home-text48">25 RON</span>
                  <span className="home-text49">/luna</span>
                </div>
              </div>
              <div className="home-bottom4">
                <div className="home-check-list4">
                  <Check Feature="Acces la tot contentul"></Check>
                  <Check Feature="Informatii premium"></Check>
                  <Check Feature="Istoric"></Check>
                  <Check Feature="Metrici detaliate"></Check>
                </div>
                <div className="home-button4">
                  <span className="home-text50">Get Premium</span>
                </div>
              </div>
            </div>
            <div className="home-plan5">
              <div className="home-top5">
                <div className="home-heading8">
                  <span className="home-text51">Student</span>
                </div>
                <div className="home-cost5">
                  <span className="home-text52">5 RON</span>
                  <span className="home-text53">/luna</span>
                </div>
              </div>
              <div className="home-bottom5">
                <div className="home-check-list5">
                  <Check Feature="Acces Premium"></Check>
                </div>
                <div className="home-button5">
                  <span className="home-text54">
                    <span className="home-text55">Get Student</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-testimonials">
        <div className="home-logo-container">
          <img alt="pastedImage" src="/logo_no_bg-200h.png" className="home-logo" />
        </div>
        <div className="home-content3">
          <div id="quotes" className="home-quotes">
            <div className="quote active-quote">
              <Quote rootClassName="quote-root-class-name"></Quote>
            </div>
            <div className="quote">
              <Quote
                Quote="Am incredere in mine si alegerile mele."
                Title="Developer @ Vista La Vista"
                Author="Author 2"
                rootClassName="quote-root-class-name"></Quote>
            </div>
            <div className="quote">
              <Quote Quote="Sunt o persoana unica in lume." Title="Designer @ OhBoy" rootClassName="quote-root-class-name"></Quote>
            </div>
          </div>
          <div className="home-buttons">
            <div id="quote-before" className="home-left">
              <svg viewBox="0 0 1024 1024" className="home-icon10">
                <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
              </svg>
            </div>
            <div id="quote-next" className="home-right">
              <svg viewBox="0 0 1024 1024" className="home-icon12">
                <path d="M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Footer text1={t('footer.caption')} text3={t('footer.policy')} Header1={t('footer.contact')} text4={t('footer.terms')}></Footer>
    </div>
  );
};

export default Home;
