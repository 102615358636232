import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import { IconButton } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

function AudioPlayer({ src }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  const handlePlayPauseClick = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      if (progress === 0) {
        logEvent(analytics, 'tutorial_begin');
      }
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (duration && currentTime) {
      const progresss = Math.trunc((currentTime / duration) * 100);
      setProgress(progresss);
    }
  }, [currentTime]);

  useEffect(() => {
    if (progress === 100) {
      logEvent(analytics, 'tutorial_complete');
    }
  }, [progress]);

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  return (
    <Box
      style={{
        background: 'white',
        width: '100%',
        height: '50px',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '300px',
        minWidth: '250px',
        flexDirection: 'row',
        padding: 5,
      }}>
      <audio src={src} ref={audioRef} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata} />
      <IconButton onClick={handlePlayPauseClick}>{isPlaying ? <Pause fill="black"></Pause> : <PlayArrow />}</IconButton>
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{
          flex: 1,
          backgroundColor: '#D9D9D9',
        }}
      />
      <div style={{ color: '#595959', paddingRight: 10, paddingLeft: 10 }}>{formatTime(duration - currentTime)}</div>
    </Box>
  );
}

function formatTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export default AudioPlayer;
