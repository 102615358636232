import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
  ro: {
    translation: {
      languages: {
        romanian: 'Română',
        english: 'Engleză',
      },
      navbar: {
        home: 'Acasă',
        about: 'Despre',
        video: "Introducere în mindfulness",
        team: 'Echipă',
        button: "Contactează-ne"
      },
      footer: {
        caption: 'Meșteșugit din ❤️ în Transilvania',
        policy: 'Politica de confidențialitate',
        contact: 'Contactează-ne',
        terms: 'Termenii și Condițiile',
      },
      home: {
        image:  "/2.png",
        description: 'Regăsește-ți echilibrul prin mindfulness și introspecție',
        iosBadge: '/download_on_the_app_store_badge.svg',
        androidBadge: '/google-play-badge-1200h.png',
        features: {
          title: 'Cum te ajuta Respiro?',
          box0: {
            title: 'Îți înțelegi stările',
            description:
              'Te ajutăm să înțelegi cum îți fluctuează emoțiile și care sunt factorii care le declanșează, pentru a avea o viață mai conștientă.',
          },
          box1: {
            title: 'Înțelegi fiecare pas',
            description:
              'Îți oferim informații despre fiecare exercițiu, beneficiile sale și modul de practică, pentru ca tu să ajungi ușor la rezultatele dorite.',
          },
          box2: {
            title: 'Pentru toate nevoile',
            description:
              'Galeria de conținut se adaptează în funcție de emoțiile tale pentru acces rapid la exercițiile de care ai nevoie.',
          },
          box3: {
            title: 'Pentru tine, pe înțelesul tău',
            description:
              ' Conținut accesibil, realizat de experți în mindfulness și psihologie, adaptat pentru România. Eficient pentru a ajunge la rezultatele dorite.',
          },
        },
        testimonials: {
          title: 'Ce spun utilizatorii Respiro',
          testimonial1:
            'De fiecare dată când primesc reminder-ele cu “Ia-ți un moment de Respiro” realizez că pot influența stările pe care le am prin exerciții de respirație sau sfaturi primite în cadrul meditațiilor.',
          testimonial2: 'Am învățat tehnici de respirație simple, pe care nu le știam. Am ascultat meditații cu poveste frumoasă.',
          testimonial3: 'Îmi activează energii pozitive.',
        },
        meditation: 'Începe cu o introducere în mindfulness',
        media: 'Apariții media',
        team: {
          title: 'Echipa Respiro',
          description:
            'Suntem o echipă de 7 oameni faini, cu experiență în dezvoltare software, pshihologie, coaching și mindfulness care își doresc să transforme grija pentru bunăstarea mentală într-un obicei accesibil oricui.',
          seeAll: 'Vezi tot',
        },
      },
      team: {
        title: 'Echipa Respiro',
        description: 'Suntem o echipă cu experiență în dezvoltare software, pshihologie, coaching și mindfulness.',
        narrators: {
          title: 'Naratorii Respiro',
          description:
            'Meditațiile din aplicație sunt narate de experți în mindfulness, psihologi CBT-ACT și actori dedicați. Ei sunt vocile care dau viață galeriei de conținut.',
          narrator1: 'Ghid mindfulness acreditat MBI, MBSR',
          narrator2: 'Primul instructor acreditat Relax Kids din România',
          narrator3: 'Psihoterapeut CBT, practician ACT și Schema Therapy',
          narrator4: 'Actor',
        },
        contact: {
          description: 'Vrei să-ți aduci conținutul în fața a mii de oameni și să-ți faci mesajul auzit?',
          button: 'Contactează-ne prin email',
        },
      },
    },
  },
  en: {
    translation: {
      languages: {
        romanian: 'Romanian',
        english: 'English',
      },
      navbar: {
        home: 'Home',
        about: 'About',
        video: "Mindfulness introduction",
        team: 'Team',
        button: "Contact us"
      },
      footer: {
        caption: 'Crafted with ❤️ in Transylvania',
        policy: 'Privacy Policy',
        contact: 'Contact Us',
        terms: 'Terms and Conditions',
      },
      home: {
        image:  "/1.png",
        description: 'Foster well-being through mindfulness and introspection',
        iosBadge: '/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg',
        androidBadge: '/google-play-badge_en.png',
        features: {
          title: 'How can Respiro help you?',
          box0: {
            title: 'Understand your moods',
            description: 'We help you understand your mood fluctuations and triggers so you can live a fully conscious life.',
          },
          box1: {
            title: 'Understanding is key',
            description:
              'Each exercise comes with precise information about benefits and way of practicing so you fell the support and maximise your results.',
          },
          box2: {
            title: 'Personalized and intimate',
            description: 'Our content gallery adapts to your emotional state, so you have immediate access to exercises that fit your needs.',
          },
          box3: {
            title: 'For your every emotion',
            description:
              'Discover a wide variety of content for stress management, self-esteem, wind-down and many more.',
          },
        },
        testimonials: {
          title: 'What our users are saying',
          testimonial1:
            'Every time I receive reminders with "Take a moment of Respiro," I realize I can influence my states through breathing exercises or advice received during meditations.',
          testimonial2: 'I learned simple breathing techniques that I did not know. I listened to meditations with a beautiful story.',
          testimonial3: 'It activates positive energies for me.',
        },
        meditation: 'Start with an introduction to mindfulness',
        media: 'Media Appearances',
        team: {
          title: 'The Respiro Team',
          description:
            'We are a team of 7 dedicated people with experience in software development, psychology, coaching and mindfulness. We want to transform the caring for mental well-being into a habit.',
          seeAll: 'See All',
        },
      },
      team: {
        title: 'The Respiro Team',
        description: 'We are a team of 7 dedicated people with experience in software development, psychology, coaching and mindfulness. We want to transform the caring for mental well-being into a habit.',
        narrators: {
          title: 'The Respiro Narrators',
          description:
            'The meditations in the app are narrated by experts in mindfulness, CBT-ACT psychologists, and dedicated actors. They are the voices that bring the content gallery to life.',
          narrator1: 'Accredited MBI, MBSR Mindfulness Guide',
          narrator2: 'First accredited Relax Kids instructor in Romania',
          narrator3: 'CBT Psychotherapist, ACT and Schema Therapy practitioner',
          narrator4: 'Actor',
        },
        contact: {
          description: 'Do you want to bring your content in front of thousands of people and make your message heard?',
          button: 'Contact us via email',
        },
      },
    },
  },
};

i18n.use(initReactI18next)
.use(LanguageDetector)

.init({
  resources,
  compatibilityJSON: 'v3',
  // lng: 'ro',
  fallbackLng: 'ro',
  detection: {
    order: ["navigator", "localStorage",], // The order in which language detection methods will be used.
    caches: ["localStorage"], // Cache the language in localStorage to remember user's language preference.
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
