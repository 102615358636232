import React from "react";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import Social from "./social";
import "./footer.css";

const Footer = (props) => {
  const navigate = useHistory();

  return (
    <div className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-content">
        <div className="footer-information">
          <div className="footer-heading">
            <div className="footer-branding">
              <img
                alt={props.image_alt1}
                src={props.image_src1}
                className="footer-image"
              />
              <h2 className="">{props.text2}</h2>
            </div>
            <span className="footer-text1">{props.text1}</span>
          </div>
          <div className="footer-socials">
            <a
              href="https://www.linkedin.com/company/respirotechnologies"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link"
            >
              <Social
                rootClassName="social-root-class-name"
                className="footer-component"
              ></Social>
            </a>
            <a
              href="https://instagram.com/respirotechnologies"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link1"
            >
              <Social
                Insider_src="/pastedimage-k0l6.svg"
                rootClassName="social-root-class-name1"
                className="footer-component1"
              ></Social>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100087551955679"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link2"
            >
              <Social
                Insider_src="/square-facebook1.svg"
                rootClassName="social-root-class-name2"
                className="footer-component2"
              ></Social>
            </a>
            <svg viewBox="0 0 877.7142857142857 1024" className="footer-icon">
              <path
                d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <span className="footer-header">{props.Header1}</span>
            <a
              href="mailto:contact@respirotechnologies.com?subject="
              className="footer-link3"
            >
              {props.Link5}
            </a>
          </div>
          <div className="footer-container">
            {/* <a
              href="https://www.termsfeed.com/live/0d591876-85b8-45fe-a33b-d742adc83a76"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link4"
            >
              {props.text4}
            </a>
            <a
              href="https://www.termsfeed.com/live/a507bad9-d87e-4c60-9474-eb1167f427d6"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link5"
            >
              {props.text3}
            </a> */}
            <div
              onClick={() => navigate.push("/terms-and-conditions")}
              // target="_blank"
              // rel="noreferrer noopener"
              className="footer-link4"
            >
              {props.text4}
            </div>
            <div
              onClick={() => navigate.push("/privacy-policy")}
              // target="_blank"
              // rel="noreferrer noopener"
              className="footer-link5"
            >
              {props.text3}
            </div>
          </div>
        </div>
      </div>
      <span className="footer-text2">{props.text}</span>
    </div>
  );
};

Footer.defaultProps = {
  Link9: "Careers",
  Link1: "Responsive Prototypes",
  Link: "Responsive Web Design",
  image_src1: "/logo_no_bg-200h.png",
  Link8: "Partners",
  Link3: "Static Website Builder",
  Link10: "Press & Media",
  text1: "Meșteșugit din ❤️ în Transilvania",
  text3: "Politica de confidențialitate",
  text2: "Respiro",
  Header1: "Contactează-ne",
  pastedImage_src:
    "https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/4a46e3f6-a2fb-4002-a0ce-bb246bd8698d/99b28711-1d54-4589-9918-a73b8ad8556b?org_if_sml=15394",
  link_text: "https://example.com",
  pastedImage_alt: "pastedImage",
  Link7: "News",
  Link2: "Design to Code",
  image_alt1: "image",
  Link5: "contact@respirotechnologies.com",
  text: "© 2023 Respiro. All Rights Reserved.",
  text4: "Termenii și Condițiile",
  Header: "Solutions",
  link_text1: "https://example.com",
  Link6: "Team",
  Link4: "Static Website Generator",
  rootClassName: "",
};

Footer.propTypes = {
  Link9: PropTypes.string,
  Link1: PropTypes.string,
  Link: PropTypes.string,
  image_src1: PropTypes.string,
  Link8: PropTypes.string,
  Link3: PropTypes.string,
  Link10: PropTypes.string,
  text1: PropTypes.string,
  text3: PropTypes.string,
  text2: PropTypes.string,
  Header1: PropTypes.string,
  pastedImage_src: PropTypes.string,
  link_text: PropTypes.string,
  pastedImage_alt: PropTypes.string,
  Link7: PropTypes.string,
  Link2: PropTypes.string,
  image_alt1: PropTypes.string,
  Link5: PropTypes.string,
  text: PropTypes.string,
  text4: PropTypes.string,
  Header: PropTypes.string,
  link_text1: PropTypes.string,
  Link6: PropTypes.string,
  Link4: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default Footer;
