import React, {useEffect} from 'react';

import { Helmet } from 'react-helmet';

import TeamMember from '../components/team-member';
import NarratorCard from '../components/narrator-card';
import Footer from '../components/footer';
import './team.css';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from 'react-i18next';

const Team = (props) => {
  const { t, i18n } = useTranslation();
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="team-container">
      <Helmet>
        <title>Respiro</title>
        <meta name="description" content="Description of the website" />
        <meta property="og:title" content="Team" />
        <meta property="og:description" content="Description of the website" />
      </Helmet>
      <div className="team-container1">
        <div className="team-heading1">
          <h2 className="team-text">
            <span className="team-text01">{t('team.title')}</span>
            <br></br>
          </h2>
          <div className="team-container2">
            <span className="team-text03">{t('team.description')}</span>
          </div>
        </div>
        <div className="team-container3">
          <div className="team-team">
            <div className="team-member-list">
              <TeamMember
                name="George"
                position="CEO"
                image_alt="George"
                image_src={'/george_corporate.jpg'}
                rootClassName="team-member-root-class-name10"
                url="https://www.linkedin.com/in/george-vasile-rosescu-3ba6a3162/"></TeamMember>
              <TeamMember
                name="Andrea"
                position="CTO"
                image_alt="Andrea Talpoș"
                image_src="/andrea.jpg"
                url={'https://www.linkedin.com/in/andrea-diana-talpos/'}
                rootClassName="team-member-root-class-name11"></TeamMember>
              <TeamMember
                name="Radu"
                position="Head of Business Development"
                image_alt="Radu Hriniuc"
                image_src="/radu.jpg"
                rootClassName="team-member-root-class-name12"
                url="https://www.linkedin.com/in/radu-hriniuc-b707b315/"></TeamMember>
              <TeamMember
                name="Simion"
                position="Head of Psychology"
                image_alt="Simion Echim"
                image_src="/simi_corporate.jpeg"
                rootClassName="team-member-root-class-name13"
                url="https://www.linkedin.com/in/simion-florin-echim-4a603a148/"></TeamMember>
              <TeamMember
                name="Bogdan"
                position="Software Engineer"
                image_alt="Bogdan Pop"
                image_src="/bogdan.jpg"
                rootClassName="team-member-root-class-name14"
                url="https://www.linkedin.com/in/bogdanpop1308/"></TeamMember>
                <TeamMember
                name="Robert"
                position="Software Engineer"
                image_alt="Robert Schlanger"
                image_src="/Robi.jpeg"
                rootClassName="team-member-root-class-name14"
                url="https://www.linkedin.com/in/robert-schlanger/"></TeamMember>
                <TeamMember
                name="Adriana"
                position="Head of Mindfulness"
                image_alt="Adriana Spătaru"
                image_src="/adriana_spataru.png"
                rootClassName="team-member-root-class-name14"
                url="https://www.linkedin.com/in/adrianaspataru/"></TeamMember>
            </div>
          </div>
        </div>
      </div>
      <div className="team-container4">
        <div className="team-container5">
          <div className="team-heading2">
            <h2 className="team-text04">
              <span className="team-text05">{t('team.narrators.title')}</span>
              <br></br>
            </h2>
            <div className="team-container6">
              <span className="team-text07">{t('team.narrators.description')}</span>
            </div>
          </div>
        </div>
      </div>
      <section className="team-slides">
        <div className='home-testimonial-container'>
          <Carousel>
            <NarratorCard
              name="Adriana Spătaru"
              position={t('team.narrators.narrator1')}
              image_src="/adriana_spataru.png"
              rootClassName="narrator-card-root-class-name"></NarratorCard>
            <NarratorCard
              name="Adriana Gog"
              image_src="/adriana_gog.jpg"
              position={t('team.narrators.narrator2')}
              rootClassName="narrator-card-root-class-name3"></NarratorCard>
            <NarratorCard
              name="Andrei Solomon"
              image_src="/andrei_solomon.jpg"
              position={t('team.narrators.narrator3')}
              rootClassName="narrator-card-root-class-name2"></NarratorCard>
            <NarratorCard
              name="Paul Petrișor"
              image_src="/paul_petrisor.jpg"
              position={t('team.narrators.narrator4')}
              rootClassName="narrator-card-root-class-name4"></NarratorCard>
          </Carousel>
        </div>
      </section>
      <div className="team-container7">
        <div className="team-container8">
          <span className="team-text08">
            {t('team.contact.description')}
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a href="mailto:radu.hriniuc@respirotechnologies.com?subject=" className="team-link">
            <span> {t('team.contact.button')}</span>
            <br></br>
          </a>
        </div>
      </div>
      <Footer
        text1={t('footer.caption')}
        text3={t('footer.policy')}
        Header1={t('footer.contact')}
        text4={t('footer.terms')}
        rootClassName="footer-root-class-name1"></Footer>
    </div>
  );
};

export default Team;
