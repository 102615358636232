import React from 'react';

import PropTypes from 'prop-types';

import './team-member.css';

const TeamMember = (props) => {
  return (
    <div className={`team-member-container ${props.rootClassName} `}>
      <div className="team-member-container1">
        <img alt={props.image_alt} src={props.image_src} loading="eager" className="team-member-image" />
        <h3 className="team-member-text">{props.name}</h3>
        <span className="team-member-text1">{props.position}</span>
      </div>
      <div className="team-member-container2">
        <a href={props.url} target="_blank" rel="noreferrer noopener" className={`img-linkedin `}>
          <img alt={props.Insider_alt} src={'/pastedimage-9nha.svg'} className="social-insider-team" />
        </a>
      </div>
    </div>
  );
};

TeamMember.defaultProps = {
  position: 'CEO',
  image_src: '/george-200w.jpeg',
  link: '',
  image_alt: 'image',
  name: 'Heading',
  rootClassName: '',
  link_text: 'https://example.com',
};

TeamMember.propTypes = {
  position: PropTypes.string,
  image_src: PropTypes.string,
  link: PropTypes.string,
  image_alt: PropTypes.string,
  name: PropTypes.string,
  rootClassName: PropTypes.string,
  link_text: PropTypes.string,
};

export default TeamMember;
