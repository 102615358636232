import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './style.css';
import Home from './views/home';
import Team from './views/team';
import PrivacyPolicy from './views/privacy-policy';
import Terms from './views/terms-therapist';
import Navbar from './components/navbar';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';


const App = () => {
  return (
    <Router>
      <div className="home-heading">
        <Navbar rootClassName="navbar-root-class-name"></Navbar>
      </div>
      <div>
        <Route component={Home} exact path="/" />
        <Route component={Team} exact path="/team" />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Route component={Terms} exact path="/terms-and-conditions" />
      </div>
    </Router>
  );
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('app'),
);
